import Layout from "../components/layout"
import SectionWrapper from "../components/sectionWrapper"
import Imprint from "../components/cards/imprint"
import React from "react"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import AnimationWrapper from "../components/cards/animationWrapper"

const ImprintPage = ({ data }) => (
  <Layout>
    <SEO title="Imprint" />
    <SectionWrapper padding={false}>
      <AnimationWrapper>
        <Imprint>{data.imprint.content}</Imprint>
      </AnimationWrapper>
    </SectionWrapper>
  </Layout>
)
export default ImprintPage

export const query = graphql`
  query imprintPage {
    imprint: wordpressPage(slug: { eq: "imprint" }) {
      id
      title
      content
    }
  }
`
