import PropTypes from "prop-types"
import React from "react"
import ReactHtmlParser from "react-html-parser"

const Imprint = props => (
  <div className="md:ml-0 sm:ml-0 sm:w-100 py-24 sm:py-8 md:w-100 xs:py-8 xs:ml-0 xs:w-100">
    <div className="xs:text-base sm:text-base text-accent  md:text-lg body-text p">
      {ReactHtmlParser(props.children)}
    </div>
  </div>
)

Imprint.propTypes = {
  title: PropTypes.string,
}

Imprint.defaultProps = {
  title: ``,
}

export default Imprint
